import gql from "graphql-tag";

export const queryCheckAvailability = gql`
  query CheckAvailability($checkAvailabilityInput: CheckAvailabilityInput!) {
    checkAvailability(checkAvailabilityInput: $checkAvailabilityInput) {
      rid
      party_size
      times
      times_available {
        time
        attribute
        credit_card_required
      }
      no_availability_reasons
    }
  }
`;

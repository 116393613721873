import gql from "graphql-tag";

export const mutationLockSlot = gql`
  mutation SlotLock($body: SlotLockInput!) {
    slotLock(body: $body) {
      expires_at
      reservation_token
    }
  }
`;

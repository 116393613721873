import React, { FC, useCallback, useEffect, useState } from "react";
import template from "lodash/template";
import FormDialog from "@RHCommerceDev/layout-form-dialog";
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
  FormControl,
  Button,
  Tabs,
  Tab,
  Box,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  useMediaQuery,
  Link
} from "@material-ui/core";
import memoize from "@RHCommerceDev/utils/memoize";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  FONT_BARON_SANS,
  FONT_BARON_SANS_ROMAN,
  FONT_CASLON
} from "@RHCommerceDev/utils/constants";
import RHCustomDatePicker from "@RHCommerceDev/component-rh-date-picker/RHCustomDatePicker";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  BlurSelectValidator,
  BlurTextValidator
} from "@RHCommerceDev/component-input-validation";
import { useTypographyStyles } from "@RHCommerceDev/hooks/useTypographyStyles";
import classNames from "classnames";
import moment from "moment";
import RHDivider from "@RHCommerceDev/component-rh-divider";

import OpenTableIcon from "@RHCommerceDev/icon-opentable";
import RHGalleryLocatorIcon from "@RHCommerceDev/icon-gallery-locator";
import RHTimeIcon from "@RHCommerceDev/icon-time";
import RHPersonIcon from "@RHCommerceDev/icon-person";
import RHDarkCalendarIcon from "@RHCommerceDev/icon-calendar-dark";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  queryBookingPolicies,
  queryCheckAvailability,
  queryRestaurants
} from "@RHCommerceDev/graphql-client/queries/opentable-reservation";
import { mutationLockSlot } from "@RHCommerceDev/graphql-client/queries/opentable-reservation/lock-slot";
import { mutationMakeReservation } from "@RHCommerceDev/graphql-client/queries/opentable-reservation/make-reservation";
import { RHPhoneInput } from "@RHCommerceDev/component-rh-phone-input";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";
import { Alert } from "@material-ui/lab";
import { useCountdown } from "@RHCommerceDev/hooks/useCountDown";
import RHCheckmarkIcon from "@RHCommerceDev/icon-checkmark";

type ReservationInquiry = {
  rid: number | undefined;
  restaurant: Maybe<Restaurant>;
  reservationDate: moment.Moment;
  reservationTime: string;
  partySize: number;
};

type ReservationData = {
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  email: Maybe<string>;
  phone: string;
  countryCode: string;
  tableType: string;
  occasion: string;
  specialRequest: string;
  emailMarketingOptIn: boolean;
};

const TABLE_TYPE_NO_PREFERENCE = "NO_PREFERENCE";

export interface OpenTableReservationDialogProps {
  restref?: number;
  open: boolean;
  onClose: () => void;
  openFromOpenTablePage?: boolean;
  selectedInquiryData?: any;
}

type TimerProps = {
  label: string;
  value: string;
};

const TAB_PANEL = {
  FIND_TABLE: "find-table",
  YOUR_DETAILS: "your-details"
};
const tabHeight = "24px";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsRoot: {
      minHeight: tabHeight,
      height: tabHeight,

      "& > div > .MuiTabs-indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent !important",
        "& > span": {
          maxWidth: "100px !important",
          width: "100px",
          backgroundColor: "#000 !important"
        }
      },

      "& > div > .MuiTabs-flexContainer": {
        gap: "12px"
      }
    },
    tabRoot: {
      minHeight: tabHeight,
      height: tabHeight,
      minWidth: 115
    },
    textCenter: { textAlign: "center" },
    paper: {
      "& .MuiInputBase-formControl": {
        borderBottomColor: "#999",
        borderTopColor: "#999",
        borderLeftColor: "#999",
        borderRightColor: "#999"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0.5px solid #999 !important"
      },
      "& .MuiDialogActions-root > button": {
        padding: "16px !important"
      },
      "& .MuiDialogContent-root": {
        background: "#F9F7F4 !important",
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          padding: "48px 30px 30px 30px"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          padding: "48px 94px 30px 94px"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          padding: "48px 32px 30px 32px"
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          padding: "48px 109px 30px 109px"
        }
      },
      "& .MuiInputLabel-formControl": {
        color: "#000 !important",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontSize: 13,
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "19px",
        letterSpacing: "0.195px",
        opacity: 1
      },
      "& .MuiOutlinedInput-input": {
        color: "#000 !important",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontSize: 13,
        height: "auto",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "19px",
        letterSpacing: "0.195px",
        padding: "14.5px 10px",
        "&::placeholder": {
          color: "#000",
          fontFeatureSettings: "'clig' off, 'liga' off",
          opacity: 1,
          fontStyle: "normal",
          fontSize: 13,
          fontWeight: 300,
          lineHeight: "19px",
          letterSpacing: "0.195px"
        }
      },
      background: "#F9F7F4 !important",
      width: BREAKPOINT_SM,
      minHeight: 584,
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: 375,
        minWidth: 375,
        margin: 0,
        position: "absolute",
        top: 0
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: 704
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: 912
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        width: 1120,
        minHeight: 584
      }
    },

    selectFieldRestaurant: {
      "& .MuiInputBase-root": {
        backgroundColor: "#f9f7f4"
      },
      "& > .MuiInputLabel-root": {
        color: "#000000 !important"
      },
      "& > div > .MuiInputAdornment-root > svg": {
        marginRight: "0px !important"
      },
      "& > div > select ": {
        borderRadius: 0,
        letterSpacing: "0.015em",
        minHeight: "44px",
        boxSizing: "border-box",
        padding: "14.5px 30px 14.5px 2px !important",
        backgroundColor: "#F9f7f4 !important",
        fontFamily: FONT_BARON_SANS,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "uppercase"
      }
    },
    selectField: {
      "& .MuiInputBase-root": {
        backgroundColor: "#f9f7f4"
      },
      "& > .MuiInputLabel-root": {
        color: "#000000 !important"
      },
      "& > div > .MuiInputAdornment-root > svg": {
        marginRight: "0px !important"
      },
      "& > div > select ": {
        borderRadius: 0,
        letterSpacing: "0.015em",
        minHeight: "44px",
        boxSizing: "border-box",
        padding: "14.5px 10px 14.5px 2px !important",
        backgroundColor: "#F9f7f4 !important",
        fontFamily: FONT_BARON_SANS,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    },
    selectField2: {
      "& .MuiInputBase-root": {
        backgroundColor: "#f9f7f4"
      },
      "& > .MuiInputLabel-root": {
        color: "#000000 !important"
      },
      "& > div > .MuiInputAdornment-root > svg": {
        marginRight: "0px !important"
      },
      "& > div > select ": {
        borderRadius: 0,
        letterSpacing: "0.015em",
        minHeight: "48px",
        boxSizing: "border-box",
        padding: "14.5px 20px !important",
        backgroundColor: "#F9f7f4 !important",
        fontFamily: FONT_BARON_SANS
      }
    },
    datePicker: {
      cursor: "pointer",
      "& > div > input ": {
        cursor: "pointer",
        padding: "14.5px 14px 14.5px 2px !important"
      },
      "& .MuiInputBase-root": {
        backgroundColor: "#f9f7f4"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: 150
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        width: 190
      }
    },
    timePicker: {
      "& .MuiInputBase-root": {
        backgroundColor: "#f9f7f4"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: 140
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        width: 150
      }
    },
    guestPicker: {
      "& > .MuiOutlinedInput-adornedStart": {
        paddingLeft: 10
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: 74
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        width: 90
      }
    },
    formTitle: {
      color: "#000",
      textAlign: "center",
      fontFamily: "RH Serif",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "19px" /* 19px */,
      letterSpacing: "0.4px",
      textTransform: "uppercase"
    },
    restaurantName: {
      color: "#BEA979",
      textAlign: "center",
      fontFamily: FONT_CASLON,
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "90%",
      letterSpacing: "-0.72px",
      textTransform: "uppercase"
    },
    tabButton: {
      color: "#404040",
      textAlign: "center",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: FONT_BARON_SANS,
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: 0,
      textTransform: "uppercase",

      "& .MuiTab-wrapper": {
        flexDirection: "row",
        gap: "5px",

        "& .MuiSvgIcon-root": {
          marginBottom: 0
        }
      }
    },
    makeReservationButtonContainer: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingTop: "32px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingTop: "52px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingTop: "0px !important"
      }
    },
    makeReservationButtonContainerWithAvailablilityInfo: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingTop: "12px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingTop: "16px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingTop: "0px !important"
      }
    },
    confirmReservationButtonContainer: {
      justifyContent: "center",
      paddingTop: "40px !important"
    },
    confirmReservationErrorContainer: {
      justifyContent: "center",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingTop: "20px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingTop: "15px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingTop: "24px !important"
      },

      "& > p": {
        color: "#CA2022",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: FONT_BARON_SANS,
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 100,
        lineHeight: "150%",
        letterSpacing: "0.195px",

        "& > div": {
          whiteSpace: "pre-wrap",
          "& > a": {
            color: "inherit",
            textDecoration: "none",
            fontWeight: 400
          }
        }
      }
    },
    makeReservationButton: {
      borderRadius: 0,
      color: "#000000",
      backgroundColor: "inherit",
      fontFamily: FONT_BARON_SANS_ROMAN,
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0.55px",
      textTransform: "uppercase",
      border: "0.5px solid #000000 !important",
      height: "48px",
      "&.Mui-disabled": {
        color: "#000000 !important",
        backgroundColor: "inherit"
      },
      "&.valid": {
        background: "#000000",
        color: "#FFF"
      },
      "&:hover": {
        background: "#404040"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: 197
      }
    },
    rhBackgroundColor: {
      backgroundColor: "#f9f7f4"
    },
    confirmReservationButton: {
      borderRadius: 0,
      backgroundColor: "#000",
      color: "#FFF",
      letterSpacing: "0.55px",
      fontFamily: FONT_BARON_SANS,
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      textTransform: "uppercase",
      border: "1px solid #000",
      height: "48px",
      "&.Mui-disabled": {
        backgroundColor: "transparent",
        color: "#000"
      },
      "&:hover": {
        backgroundColor: "#000",
        color: "#FFF",
        border: "1px solid #000"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: 197
      }
    },
    tabs: {
      "& > div > div > .MuiTab-textColorSecondary": {
        color: "#404040",
        opacity: 0.5
      },
      "& > div > div > .Mui-selected": {
        color: "#000",
        opacity: 1
      },
      "& > div > .MuiTabs-indicator": {
        backgroundColor: "#404040",
        height: "1px"
      },
      "& .checkmark": {
        fontSize: "16px !important"
      }
    },
    openTableIcon: {
      width: 80,
      height: 18
    },
    openTablePoweredBy: {
      fontSize: "11px",
      fontWeight: 300,
      lineHeight: "12.1px",
      letterSpacing: "0.44px",
      textAlign: "center",
      marginBottom: "4px",
      fontFamily: FONT_BARON_SANS
    },
    reservationSummary: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingTop: 40,
        gap: "24px"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingTop: 60,
        flexDirection: "row",
        gap: "45px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingTop: 0,
        flexDirection: "column",
        gap: "40px"
      }
    },
    yourDetailsFormFooter: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingTop: 20,
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingTop: 60,
        width: "100%"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingTop: 20
      }
    },
    formWrapper: {
      width: "100%",
      maxWidth: "872px",
      margin: "0 auto"
    },
    yourDetailsFormContainer: {
      margin: "0 auto",
      "& .MuiCheckbox-root": {
        backgroundColor: "#f9f7f4 !important",
        border: "0.5px solid #999 !important",
        "&:hover": {
          backgroundColor: "#f9f7f4 !important"
        }
      },
      "& > .MuiFormControlLabel-root": {
        alignItems: "flex-start",
        marginRight: 0
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: 240
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: 488
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: 400
      }
    },
    specialRequestTextArea: {
      "& > .MuiOutlinedInput-root": {
        padding: 0
      }
    },
    reservationSummaryLineItem: {
      color: "#404040",
      fontFamily: FONT_BARON_SANS,
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "uppercase"
    },
    reservationSummaryAddressLine: {
      color: "#404040",
      fontFamily: FONT_BARON_SANS,
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      display: "flex",
      flexDirection: "column",
      gap: "8px"
    },
    getDirectionsTextLink: {
      color: "#404040",
      fontFamily: FONT_BARON_SANS,
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textDecorationLine: "underline",
      paddingTop: "8px"
    },
    restaurantNote: {
      color: "#000",
      fontFamily: FONT_BARON_SANS,
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "150%",
      letterSpacing: "0.165px"
    },
    marketingOptIn: {
      color: "#000",
      fontFamily: FONT_BARON_SANS,
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "150%",
      letterSpacing: "0.195px"
    },
    guestDetailsField: {
      "& > .MuiOutlinedInput-root": {
        height: "48px",

        "& > input": {
          color: "#000"
        }
      }
    },
    reservationDetailsFormCheckbox: {
      alignItems: "flex-start"
    },
    availabilityInfoContainer: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingTop: 40
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingTop: 60
      }
    },
    findTableContainer: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        padding: "40px 0 30px"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        padding: "60px 0 30px"
      }
    },
    yourDetailsPanelContainer: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        padding: "40px 0 20px"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        padding: "24px 0 20px"
      }
    },
    availabilityInfo: {
      color: "#000000",
      fontFamily: FONT_BARON_SANS,
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 100,
      lineHeight: "150%",
      letterSpacing: "0.195px",
      padding: "0px 20px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        padding: 0
      },
      "& > div": {
        whiteSpace: "pre-wrap",
        "& > a": {
          color: "inherit",
          textDecoration: "none",
          fontWeight: 400
        }
      }
    },
    availableSlotsContainer: {
      paddingTop: 24,
      justifyContent: "center",
      columnGap: 10,
      rowGap: 8,
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        paddingTop: 30
      }
    },
    getEmailUpdatesCheckbox: {
      marginTop: "3px !important",
      marginRight: "8px !important",
      "& > span > input": {
        width: "16px",
        height: "16px"
      }
    },
    availableSlotButton: {
      color: "#808080",
      fontFamily: FONT_BARON_SANS,
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0.55px",
      backgroundColor: "#f9f7f4",
      textTransform: "none",
      borderRadius: 0,
      height: 32,
      "&.valid": {
        background: "#000000",
        color: "#FFF"
      },
      "&:hover": {
        background: "#000",
        color: "#FFF",
        border: "1px solid #000"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: 88
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: 90
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: 158
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        width: 172,
        paddingTop: 15
      }
    },
    bookingConfirmationMessage: {
      color: "#000000",
      fontFamily: FONT_BARON_SANS_ROMAN,
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0.195px",
      textAlign: "center",
      fontFeatureSettings: "'clig' off, 'liga' off"
    },
    countdownTimer: {
      marginBottom: 30,
      flexGrow: 1,
      justifyContent: "center",
      backgroundColor: "#f1f0ed",
      borderRadius: 0,

      "& .MuiAlert-message": {
        padding: "4px 0",
        fontFamily: FONT_BARON_SANS,
        fontSize: 13,
        letterSpacing: "0.195px",
        fontWeight: 100,
        color: "#000",
        fontStyle: "normal",
        fontFeatureSettings: "'clig' off, 'liga' off",
        lineHeight: "150%",
        backgroundColor: "#f1f0ed"
      }
    },
    termsOfUse: {
      position: "relative",
      fontSize: "11px !important",
      letterSpacing: "0.02em",
      lineHeight: "150%",
      fontWeight: 300,
      fontFamily: FONT_BARON_SANS,
      color: "#000",
      textAlign: "center",
      paddingTop: 30,

      "& > span > a": {
        display: "inline-flex",
        padding: "0 5px"
      }
    },
    bookingConfirmationContainer: {
      position: "absolute",
      width: "100%",
      height: `calc(100% - 112px)`,
      top: 56,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  })
);

const hoursOfDay = [
  "12am",
  "1am",
  "2am",
  "3am",
  "4am",
  "5am",
  "6am",
  "7am",
  "8am",
  "9am",
  "10am",
  "11am",
  "12pm",
  "1pm",
  "2pm",
  "3pm",
  "4pm",
  "5pm",
  "6pm",
  "7pm",
  "8pm",
  "9pm",
  "10pm",
  "11pm"
];

const generateHoursInterval = (restaurantTimings, selectedDay, interval) => {
  if (!restaurantTimings) return [];
  const times: TimerProps[] = [];
  const intervalMins = interval === 30 ? [0, 30] : [0, 15, 30, 45];
  const timing = restaurantTimings[selectedDay];
  const startIndex = hoursOfDay.indexOf(timing[0]);
  const endIndex = hoursOfDay.indexOf(timing[1]);

  Array.from({ length: 24 }, (v, index) => {
    if (index < startIndex || index > endIndex) return;
    intervalMins.map(min => {
      const label = moment({ hour: index, minute: min }).format("h:mm a");
      const value = moment({ hour: index, minute: min }).format("HH:mm");
      if (index === endIndex && min !== 0) return;
      times.push({ label, value });
    });
  });
  return times;
};

const RESERVATION_FORM_STATE = {
  PRISTINE: "pristine",
  SLOT_AVAILABLE: "slot-available",
  SLOT_NOT_AVAILABLE: "slot-not-available",
  BOOKING_DONE: "booking-done"
};

const ERROR_STATE = {
  NotActive: "ERROR_RESTAURANT_NOT_ACTIVE",
  Offline: "ERROR_RESTAURANT_OFFLINE",
  NoTimesExist: "ERROR_TIME_SLOT_NOT_EXIST",
  BlockedTimesExist: "ERROR_TIME_SLOT_BLOCKED",
  BelowMinPartySize: "ERROR_BELOW_MIN_SEATS_ALLOWED",
  AboveMaxPartySize: "ERROR_ABOVE_MAX_SEATS_ALLOWED",
  BlockedDay: "ERROR_DAY_NOT_AVAILABLE",
  SameDayCutoff: "ERROR_BEYOND_SAME_DAY_CUTOFF",
  EarlyCutoff: "ERROR_BEYOND_EARLY_CUTOFF",
  TooFarInAdvance: "ERROR_TOO_FAR_IN_ADVANCE",
  NotFarEnoughInAdvance: "ERROR_NOT_FAR_ENOUGH_IN_ADVANCE",
  TimeSlotNotAvailable: "ERROR_TIME_SLOT_NOT_AVAILABLE",
  default: "ERROR_DEFAULT"
};

const ERROR_DESCRIPTION = {
  NotActive: "The restaurant is not in an active state.",
  Offline:
    "The restaurant is temporarily offline. We cannot communicate with the restaurant.",
  NoTimesExist:
    "There simply are no available times for the requested date, time, and time window of interest. There is no indication of blocked inventory.",
  BlockedTimesExist:
    "Although there are no available times, the restaurant has blocked or empty manager time slots, which may be available for walk-ins or well-connected diners. These have been withheld from the online reservations pool.",
  BelowMinPartySize:
    "The requested party size is below the minimum allowed for online reservations.",
  AboveMaxPartySize:
    "The requested party size is above the maximum allowed for online reservations.",
  BlockedDay:
    "Online reservations have been disallowed by the restaurant specifically for this day.",
  SameDayCutoff:
    "There is a cutoff time for same-day reservations, and this search request occurs after the cutoff time.",
  EarlyCutoff:
    "There is a cutoff date 1 or more days in advance of the reservation date, and this search request occurs after the cutoff date.",
  TooFarInAdvance:
    "The restaurant does not allow reservations to be made more than 30 days in advance, and the requested reservation date exceeds this limit.",
  NotFarEnoughInAdvance:
    "OpenTable will not allow a reservation to be made less than 5 minutes in advance.",
  TimeSlotNotAvailable:
    "Reservations are currently full for the time you selected. Please choose from one of our available times below.",
  default:
    "Your reservation could not be completed at this time. Please contact the restaurant directly for assistance."
};

const SLOT_LOCK_TIMER_MESSAGE = {
  ACTIVE: "Due to limited availability, we can hold this table for you for",
  EXPIRED:
    "You can still try to complete your booking, but this table may no longer be available."
};

const initialRestaurantInquiryData = {
  rid: undefined,
  restaurant: null,
  reservationDate: moment(),
  reservationTime: "19:00",
  partySize: 2
};

const initialRestaurantReservationData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  countryCode: "",
  tableType: TABLE_TYPE_NO_PREFERENCE,
  occasion: "",
  specialRequest: "",
  emailMarketingOptIn: false
};

const SlotLockCountDownTimer = ({ expiresAt, pageContent }: any) => {
  const classes = useStyles();
  const [slotLockTimerMessage, setSlotLockTimerMessage] = useState(
    pageContent?.SLOT_LOCK_TIMER_MESSAGE_ACTIVE ||
      SLOT_LOCK_TIMER_MESSAGE.ACTIVE
  );
  const [minutes, seconds, isExpired] = useCountdown(expiresAt);

  useEffect(() => {
    if (isExpired)
      setSlotLockTimerMessage(
        pageContent?.SLOT_LOCK_TIMER_MESSAGE_EXPIRED ||
          SLOT_LOCK_TIMER_MESSAGE.EXPIRED
      );
  }, [isExpired, pageContent]);

  return (
    <>
      {isExpired && (
        <Grid container justify="center">
          <Alert
            icon={false}
            severity="info"
            className={classes.countdownTimer}
          >
            {slotLockTimerMessage}
          </Alert>
        </Grid>
      )}
      {!isExpired && (
        <Grid container justify="center">
          <Alert
            icon={false}
            severity="info"
            className={classes.countdownTimer}
          >
            {slotLockTimerMessage}{" "}
            <span style={{ fontWeight: 400 }}>
              {minutes}:{String(seconds).padStart(2, "0")} minutes
            </span>
          </Alert>
        </Grid>
      )}
    </>
  );
};

export const OpenTableReservationDialog: FC<
  OpenTableReservationDialogProps
> = ({
  restref,
  open,
  onClose,
  openFromOpenTablePage = false,
  selectedInquiryData = initialRestaurantInquiryData
}) => {
  const classes = useStyles();
  const { pageContent } = useFetchModel(`/admin/opentable-reservation`, true);
  const rhTypoClasses = useTypographyStyles({
    keys: ["rhBaseH4", "rhBaseBody1", "textLink", "uppercaseText"]
  });
  const [openState, setOpenState] = useState<boolean>(open);

  const isSmallTablet = useMediaQuery<Theme>(theme =>
    theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)
  );
  const smDown = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down(BREAKPOINT_SM)
  );
  const mdDown = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down(BREAKPOINT_MD)
  );
  const [timeList, setTimeList] = useState<TimerProps[]>([]);
  const [timeList15MinsInterval, setTimeList15MinsInterval] = useState<
    TimerProps[]
  >([]);

  const [activeTab, setActiveTab] = useState(TAB_PANEL.FIND_TABLE);
  const [reservationFormState, setReservationFormState] = useState<string>(
    RESERVATION_FORM_STATE.PRISTINE
  );
  const [makeReservationError, setMakeReservationError] = useState<string>("");
  const [confirmReservationError, setConfirmReservationError] =
    useState<string>("");
  const [reservationToken, setReservationToken] = useState<string>("");
  const [confirmationNumber, setConfirmationNumber] = useState<number | null>(
    null
  );
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(
    null
  );
  const [alternateTimeSlots, setAlternateTimeSlots] = useState<string[]>([]);
  const [tableTypeOptions, setTableTypeOptions] = useState<string[]>([
    "default"
  ]);
  const [availableTimes, setAvailableTimes] = useState<any[] | undefined>([]);
  const [slotLockTokenExpiresAt, setSlotLockTokenExpiresAt] = useState<
    string | null
  >(null);

  const [partySize, setPartySize] = useState(12);

  const [restaurantTimings, setRestaurantTimings] = useState<any>(null);

  const [restaurantInquiryData, setRestaurantInquiryData] =
    useState<ReservationInquiry>(selectedInquiryData);

  const [restaurantReservationData, setRestaurantReservationData] =
    useState<ReservationData>(initialRestaurantReservationData);

  useEffect(() => {
    setOpenState(open);
    initializeReservationForm();
    if (open) {
      getRestaurants();
      if (openFromOpenTablePage && selectedInquiryData?.rid) {
        setRestaurantInquiryData({
          rid: restref,
          ...selectedInquiryData
        });
        checkAvailabilityOnFormSubmit(selectedInquiryData);
      } else {
        setRestaurantInquiryData({
          ...restaurantInquiryData,
          rid: restref
        });
      }
    }
  }, [open]);

  useEffect(() => {
    if (restaurantInquiryData.restaurant) {
      parseRestaurantTimings(restaurantInquiryData.restaurant);
      if (restaurantInquiryData.restaurant?.maxGuestNumber) {
        setPartySize(
          parseInt(restaurantInquiryData.restaurant?.maxGuestNumber!)
        );
      }
    }
  }, [restaurantInquiryData.rid]);

  const parseRestaurantTimings = (selectedRestaurant: any) => {
    if (!selectedRestaurant) return;
    const restaurantTimings = {};

    selectedRestaurant?.hours?.map(hour => {
      const tokens = hour.split(": ");
      const day = tokens[0].toLowerCase();
      const timings = tokens[1].trim().split("-");
      restaurantTimings[day] = timings;
    });
    setRestaurantTimings(restaurantTimings);
    setTimeList(
      generateHoursInterval(
        restaurantTimings,
        restaurantInquiryData.reservationDate
          .format("dddd")
          .toLocaleLowerCase(),
        30
      )
    );
    setTimeList15MinsInterval(
      generateHoursInterval(
        restaurantTimings,
        restaurantInquiryData.reservationDate
          .format("dddd")
          .toLocaleLowerCase(),
        15
      )
    );
  };

  const handleClose = () => {
    initializeReservationForm();
    setOpenState(false);
    onClose();
  };

  const initializeReservationForm = () => {
    setReservationFormState(RESERVATION_FORM_STATE.PRISTINE);
    setConfirmationNumber(null);
    setReservationToken("");
    setMakeReservationError("");
    setConfirmReservationError("");
    setActiveTab(TAB_PANEL.FIND_TABLE);
    setAlternateTimeSlots([]);
    setAvailableTimes([]);
    setRestaurantTimings(null);
    setSlotLockTokenExpiresAt(null);
    setRestaurantInquiryData({ ...selectedInquiryData });
    setRestaurantReservationData({ ...initialRestaurantReservationData });
  };

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      if (newValue === TAB_PANEL.YOUR_DETAILS) return;
      setActiveTab(newValue);
    },
    []
  );

  const handleSelectTimeSlot = useCallback(
    slot => {
      const selectedTimeSlot = timeList15MinsInterval.filter(
        time => time.label === slot
      )[0];
      setRestaurantInquiryData({
        ...restaurantInquiryData,
        reservationTime: selectedTimeSlot.value
      });
      const { rid, partySize, reservationDate } = restaurantInquiryData;

      const availableAttributes = availableTimes
        ?.filter(
          t =>
            t.time ===
            moment(reservationDate).format("yyyy-MM-DD") +
              "T" +
              selectedTimeSlot.value
        )
        .map(a => a.attribute);
      setTableTypeOptions(availableAttributes as string[]);

      getBookingPolicies({
        variables: {
          rid: rid,
          partySize: partySize,
          date: moment(reservationDate).format("yyyy-MM-DD"),
          time: selectedTimeSlot.value
        }
      });

      // If available table type is only default, proceed with slot lock
      if (availableAttributes?.length === 1) {
        setRestaurantReservationData({
          ...restaurantReservationData,
          tableType: availableAttributes[0]
        });
        slotLock({
          variables: {
            body: {
              restaurantId: rid,
              partySize: partySize,
              dateTime:
                moment(reservationDate).format("yyyy-MM-DD") +
                `T` +
                selectedTimeSlot.value,
              reservationAttribute: availableAttributes[0]
            }
          }
        });
      } else {
        setActiveTab(TAB_PANEL.YOUR_DETAILS);
      }
    },
    [restaurantInquiryData, timeList, timeList15MinsInterval, availableTimes]
  );

  const isTimeSlotAvailable = (availableTimes: string[] | undefined) => {
    if (!availableTimes) return false;
    const { reservationDate, reservationTime } = restaurantInquiryData;
    const selectedSlot =
      moment(reservationDate).format("yyyy-MM-DD") + `T` + reservationTime;
    return availableTimes.includes(selectedSlot);
  };

  const isOnlyCreditCardSlotsAvailable = (
    times_available: TimesAvailable[] | undefined
  ) => {
    const nonCreditCardSlots = times_available?.filter(
      time => !time.credit_card_required
    );
    return nonCreditCardSlots?.length === 0;
  };

  const populateAlternateTimeSlots = (times: string[] | undefined) => {
    if (!times) return;
    const availableTimes = times.map(time => time.split("T")[1]);
    const filteredTimeInterval15Mins = timeList15MinsInterval?.filter(
      interval => availableTimes.includes(interval.value)
    );
    const availabeTimesLabels = filteredTimeInterval15Mins.map(
      interval => interval.label
    );
    setAlternateTimeSlots(availabeTimesLabels);
  };

  /** CountryCodes supported by OpenTable are not exactly iso2.
   * Handle cases here. 'gb' is handled for you
   */
  const getCountryCodeFromISO = (countryCode: string) => {
    switch (countryCode) {
      case "gb":
        return "UK";
      default:
        return countryCode?.toUpperCase();
    }
  };

  const getReservationTimeLabel = useCallback(() => {
    const selected = [...timeList15MinsInterval].filter(
      interval => interval.value === restaurantInquiryData.reservationTime
    );
    return selected?.length > 0 && selected[0].label;
  }, [restaurantInquiryData.reservationTime, timeList15MinsInterval]);

  const handleMakeReservationError = errorMessage => {
    setReservationFormState(RESERVATION_FORM_STATE.SLOT_NOT_AVAILABLE);
    setMakeReservationError(errorMessage);
  };

  /** Check availability query */
  const [
    checkAvailability,
    { data: { checkAvailability: checkAvailabilityResult } = {} as any }
  ] = useLazyQuery<Query>(queryCheckAvailability, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      const noAvailabilityReason =
        checkAvailabilityResult?.no_availability_reasons &&
        checkAvailabilityResult?.no_availability_reasons.length > 0 &&
        checkAvailabilityResult?.no_availability_reasons[0];

      if (noAvailabilityReason) {
        // Slot not available for booking, show booking error message
        setMakeReservationError(noAvailabilityReason);
        setReservationFormState(RESERVATION_FORM_STATE.SLOT_NOT_AVAILABLE);
      } else {
        if (!isTimeSlotAvailable(checkAvailabilityResult.times)) {
          setMakeReservationError("TimeSlotNotAvailable");
          setReservationFormState(RESERVATION_FORM_STATE.SLOT_NOT_AVAILABLE);
        }
        if (
          isOnlyCreditCardSlotsAvailable(
            checkAvailabilityResult.times_available
          )
        ) {
          setMakeReservationError("AboveMaxPartySize");
          setReservationFormState(RESERVATION_FORM_STATE.SLOT_NOT_AVAILABLE);
          return;
        }
        // populate alternate timeslots irrespective of whether selected slot is available or not
        populateAlternateTimeSlots(checkAvailabilityResult.times);
        setAvailableTimes(checkAvailabilityResult.times_available);
      }
    },
    onError: handleMakeReservationError
  });

  /** Get booking policies for the selected restaurant */
  const [
    getBookingPolicies,
    { data: { getBookingPolicies: bookingPoliciesResult } = {} as Query }
  ] = useLazyQuery<Query>(queryBookingPolicies, {
    fetchPolicy: "cache-and-network"
  });

  /** Get a list of RH restaurants */
  const [
    getRestaurants,
    { data: { restaurants: allRestaurants } = {} as Query }
  ] = useLazyQuery<Query>(queryRestaurants, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      if (restref) {
        const selectedRestaurant = allRestaurants.filter(
          restaurant => parseInt(restaurant.openTableID!) === restref
        )[0];
        parseRestaurantTimings(selectedRestaurant);
        setRestaurantInquiryData({
          ...restaurantInquiryData,
          restaurant: selectedRestaurant
        });
      }
    }
  });

  const [slotLock] = useMutation<Mutation>(mutationLockSlot, {
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onError: handleMakeReservationError,
    onCompleted: data => {
      const { reservation_token, expires_at } = data.slotLock;
      setReservationToken(reservation_token);
      setActiveTab(TAB_PANEL.YOUR_DETAILS);
      setSlotLockTokenExpiresAt(expires_at);
    }
  });

  const [makeReservation] = useMutation<Mutation>(mutationMakeReservation, {
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onError: () => {
      const [textBy, textPhone, textOr, textEmail] =
        getRestaurantPhoneAndEmailText();
      const reservationError = `${pageContent?.ERROR_DEFAULT} ${textBy} ${textPhone} ${textOr} ${textEmail}`;
      setConfirmReservationError(reservationError);
    },
    onCompleted: data => {
      const { rid = "" } = restaurantInquiryData;
      const selectedRestaurant = allRestaurants?.find(
        a => a?.openTableID === rid?.toString()
      );
      document?.body?.dispatchEvent(
        new CustomEvent("cta_click", {
          detail: {
            item: {
              isMakeOrConfirmReservation: true,
              restaurantName: selectedRestaurant?.name ?? "",
              cat: pageContent?.CONFIRM_RESERVATION_BUTTON_TEXT
            }
          }
        })
      );
      const confirmationResponse: MakeReservationResponse =
        data.makeReservation;
      setConfirmationNumber(confirmationResponse.confirmation_number);
      setConfirmationMessage(confirmationResponse.message);
      setReservationFormState(RESERVATION_FORM_STATE.BOOKING_DONE);
    }
  });

  const getRestaurantPhoneAndEmailText = () => {
    const { phoneNumber, generalEmailAddress } =
      restaurantInquiryData.restaurant || ({} as Restaurant);
    let textPhone = "",
      textEmail = "",
      textBy = "",
      textOr = "";
    if (phoneNumber && phoneNumber.length > 0)
      textPhone = `<a href="tel:${phoneNumber}">${phoneNumber}</a>`;
    if (generalEmailAddress && generalEmailAddress.length > 0)
      textEmail = `<a href="mailto:${generalEmailAddress}">${generalEmailAddress}.</a>`;
    if (phoneNumber || generalEmailAddress)
      textBy = `${pageContent?.at || "by"}`;
    if (phoneNumber && generalEmailAddress)
      textOr = `${pageContent?.or || "or"}`;

    return [textBy, textPhone, textOr, textEmail];
  };

  const getSlotNotAvailableErrorMessage = (rid: number | undefined) => {
    const errorState =
      ERROR_STATE[makeReservationError] || ERROR_STATE["default"];

    if (
      errorState === "ERROR_DAY_NOT_AVAILABLE" ||
      errorState === "ERROR_TIME_SLOT_NOT_AVAILABLE"
    ) {
      return pageContent[errorState] || ERROR_DESCRIPTION[makeReservationError];
    } else {
      const [textBy, textPhone, textOr, textEmail] =
        getRestaurantPhoneAndEmailText();
      const errorMessageTemplate = template(
        `${pageContent[errorState]} ${textBy} ${textPhone} ${textOr} ${textEmail}` ||
          ERROR_DESCRIPTION[makeReservationError]
      );
      return errorMessageTemplate({
        OPEN_TABLE_RESERVATION_URL: `${pageContent?.OPEN_TABLE_RESERVATION_URL}${rid}`
      });
    }
  };

  const renderSlotNotAvailableInfo = (rid: number | undefined) => {
    const errorMessage = getSlotNotAvailableErrorMessage(rid);
    return (
      <Grid container justify="center">
        <Grid
          item
          style={{ textAlign: "center", width: smDown ? "300px" : "auto" }}
          className={classes.availabilityInfoContainer}
        >
          <Typography
            className={classes.availabilityInfo}
            style={{
              color: alternateTimeSlots.length === 0 ? "#CA2022" : "inherit"
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const checkAvailabilityOnFormSubmit = (
    selectedFormData = restaurantInquiryData
  ) => {
    setReservationFormState(RESERVATION_FORM_STATE.PRISTINE);
    setAlternateTimeSlots([]);
    const { rid, reservationDate, reservationTime, partySize } =
      selectedFormData;
    const availabilitySearchInput: CheckAvailabilityInput = {
      restaurantId: rid!,
      partySize: partySize,
      startDateTime:
        moment(reservationDate).format("yyyy-MM-DD") + `T` + reservationTime,
      forwardMinutes: parseInt(pageContent?.MINUTES_LOOKUP_RANGE) || 30,
      backwardMinutes: parseInt(pageContent?.MINUTES_LOOKUP_RANGE) || 30
    };

    checkAvailability({
      variables: {
        checkAvailabilityInput: availabilitySearchInput
      }
    });
  };

  const restaurantEnquiryForm = () => {
    const onSubmit = async () => {
      checkAvailabilityOnFormSubmit();
    };

    const renderAlternateTimeSlots = () => {
      return (
        <Grid container className={classes.availableSlotsContainer}>
          {alternateTimeSlots.map(availableSlot => {
            return (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.availableSlotButton}
                  onClick={() => handleSelectTimeSlot(availableSlot)}
                >
                  {availableSlot}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      );
    };

    const { reservationTime, rid } = restaurantInquiryData;
    return (
      <Grid container>
        <ValidatorForm
          debounceTime={1000}
          instantValidate={false}
          onSubmit={onSubmit}
          data-testid="opentable-enquiry-form"
          style={{ width: "100%" }}
        >
          <Grid container justify="center" style={{ gap: "8px" }}>
            <Grid item style={{ padding: 0 }}>
              <BlurSelectValidator
                id="restaurant"
                name="restaurant"
                fullWidth
                select
                variant="outlined"
                value={restaurantInquiryData.rid}
                className={classes.selectFieldRestaurant}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value === "") return;
                  const selectedRID = parseInt(event.target.value);
                  const selectedRestaurant = allRestaurants.filter(
                    restaurant =>
                      parseInt(restaurant.openTableID!) === selectedRID
                  )[0];
                  setRestaurantInquiryData({
                    ...restaurantInquiryData,
                    restaurant: selectedRestaurant,
                    rid: selectedRID
                  });
                  setReservationFormState(RESERVATION_FORM_STATE.PRISTINE);
                }}
                inputProps={{
                  "data-testid": "restaurant"
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RHGalleryLocatorIcon
                        style={{ width: "8px", height: "11px" }}
                      />
                    </InputAdornment>
                  )
                }}
                style={{ width: "240px" }}
              >
                <option key={"RESTAURANT"} value={undefined}>
                  {`Select a restaurant`}
                </option>
                {allRestaurants &&
                  allRestaurants
                    .filter(restaurant => restaurant.openTableID != null)
                    .map(restaurant => (
                      <option
                        key={`rid-${restaurant.openTableID}`}
                        value={restaurant.openTableID!}
                      >
                        {restaurant.name}
                      </option>
                    ))}
              </BlurSelectValidator>
            </Grid>
            <Grid item style={{ padding: 0 }}>
              <RHCustomDatePicker
                name="eventDateTime"
                id="eventDateTime"
                value={restaurantInquiryData.reservationDate}
                inputProps={{
                  "data-testid": "eventDateTime"
                }}
                format="yyyy-MM-DD"
                onChange={(date: moment.Moment) => {
                  setReservationFormState(RESERVATION_FORM_STATE.PRISTINE);
                  setRestaurantInquiryData({
                    ...restaurantInquiryData,
                    reservationDate: date
                  });
                  setTimeList(
                    generateHoursInterval(
                      restaurantTimings,
                      date.format("dddd").toLocaleLowerCase(),
                      30
                    )
                  );
                  setTimeList15MinsInterval(
                    generateHoursInterval(
                      restaurantTimings,
                      date.format("dddd").toLocaleLowerCase(),
                      15
                    )
                  );
                }}
                fullWidth
                className={classes.datePicker}
              />
            </Grid>

            <Grid item style={{ padding: 0 }}>
              <BlurSelectValidator
                id="startTime"
                name="startTime"
                className={[classes.selectField, classes.timePicker]}
                fullWidth
                select
                variant="outlined"
                value={restaurantInquiryData.reservationTime}
                onChange={e => {
                  setReservationFormState(RESERVATION_FORM_STATE.PRISTINE);
                  setRestaurantInquiryData({
                    ...restaurantInquiryData,
                    reservationTime: e.target.value
                  });
                }}
                inputProps={{
                  "data-testid": "startTime"
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RHTimeIcon style={{ width: "12px", height: "12px" }} />
                    </InputAdornment>
                  )
                }}
              >
                <option key="defaultTime" value={pageContent?.PICK_TIME}>
                  {pageContent?.PICK_TIME}
                </option>
                {[...timeList].map(time => (
                  <option key={time.label} value={time.value}>
                    {time.label}
                  </option>
                ))}
              </BlurSelectValidator>
            </Grid>
            <Grid item style={{ padding: 0 }}>
              <BlurSelectValidator
                id="partySize"
                name="partySize"
                fullWidth
                select
                variant="outlined"
                value={restaurantInquiryData.partySize}
                className={[classes.selectField, classes.guestPicker]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setReservationFormState(RESERVATION_FORM_STATE.PRISTINE);
                  setRestaurantInquiryData({
                    ...restaurantInquiryData,
                    partySize: parseInt(event.target.value)
                  });
                }}
                inputProps={{
                  "data-testid": "partySize"
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RHPersonIcon style={{ width: "12px", height: "13px" }} />
                    </InputAdornment>
                  )
                }}
              >
                {[...Array(partySize).keys()].map(number => (
                  <option key={number} value={number + 1}>
                    {number + 1}
                  </option>
                ))}
              </BlurSelectValidator>
            </Grid>
            {mdDown &&
              RESERVATION_FORM_STATE.SLOT_NOT_AVAILABLE ===
                reservationFormState &&
              renderSlotNotAvailableInfo(rid)}
            {mdDown &&
              alternateTimeSlots &&
              alternateTimeSlots.length > 0 &&
              renderAlternateTimeSlots()}
            <Grid
              item
              style={{ padding: 0 }}
              className={
                RESERVATION_FORM_STATE.SLOT_NOT_AVAILABLE ===
                reservationFormState
                  ? classes.makeReservationButtonContainerWithAvailablilityInfo
                  : classes.makeReservationButtonContainer
              }
            >
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                  className={
                    reservationTime &&
                    reservationTime !== pageContent?.PICK_TIME &&
                    rid
                      ? `${classes.makeReservationButton} valid`
                      : `${classes.makeReservationButton}`
                  }
                  disabled={
                    !reservationTime ||
                    reservationTime == pageContent?.PICK_TIME ||
                    !rid
                  }
                >
                  {pageContent?.MAKE_RESERVATION_BUTTON_TEXT}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
          {!mdDown &&
            RESERVATION_FORM_STATE.SLOT_NOT_AVAILABLE ===
              reservationFormState &&
            renderSlotNotAvailableInfo(rid)}
          {!mdDown &&
            alternateTimeSlots &&
            alternateTimeSlots.length > 0 &&
            renderAlternateTimeSlots()}
        </ValidatorForm>
      </Grid>
    );
  };

  const reservationDetailsForm = () => {
    const { restaurant, reservationDate, reservationTime, partySize, rid } =
      restaurantInquiryData;

    const { firstName, lastName, email, phone, tableType } =
      restaurantReservationData;

    let Policies;
    if (bookingPoliciesResult) {
      ({ Policies } = bookingPoliciesResult);
    }

    const onSubmit = async () => {
      const { rid } = restaurantInquiryData;

      const {
        firstName,
        lastName,
        email,
        phone,
        countryCode,
        tableType,
        specialRequest,
        occasion,
        emailMarketingOptIn
      } = restaurantReservationData;

      makeReservation({
        variables: {
          body: {
            restaurantId: rid,
            reservationToken: reservationToken,
            firstName: firstName,
            lastName: lastName,
            emailAddress: email,
            phone: {
              number: phone,
              countryCode: countryCode
            },
            reservationAttribute: tableType,
            specialRequest:
              occasion && occasion.length > 1
                ? specialRequest.concat(` (Occasion: ${occasion})`)
                : specialRequest,
            restaurantEmailMarketingOptIn: emailMarketingOptIn
          }
        }
      });
    };

    const getTableTypeDropdownLabel = (tableType: string) => {
      switch (tableType) {
        case "default":
          return pageContent?.TABLE_TYPE_OPTION_DEFAULT;
        case "bar":
          return pageContent?.TABLE_TYPE_OPTION_BAR;
        case "highTop":
          return pageContent?.TABLE_TYPE_OPTION_HIGHTOP;
        case "counter":
          return pageContent?.TABLE_TYPE_OPTION_COUNTER;
        case "outdoor":
          return pageContent?.TABLE_TYPE_OPTION_OUTDOOR;
      }
    };

    return (
      <Grid container>
        {slotLockTokenExpiresAt && !confirmReservationError && (
          <SlotLockCountDownTimer
            expiresAt={slotLockTokenExpiresAt}
            pageContent={pageContent}
          />
        )}
        <ValidatorForm
          debounceTime={1000}
          instantValidate={false}
          onSubmit={onSubmit}
          data-testid="opentable-reservation-form"
          className={classes.formWrapper}
          style={{ width: "100%" }}
        >
          <Grid container justify={smDown ? "center" : "space-between"}>
            <Grid item className={classes.yourDetailsFormContainer}>
              <Grid container spacing={2}>
                <Grid item container xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <BlurSelectValidator
                      id="table-type"
                      name="table-type"
                      fullWidth
                      select
                      variant="outlined"
                      label={
                        tableTypeOptions.length === 1 ||
                        restaurantReservationData.tableType !==
                          TABLE_TYPE_NO_PREFERENCE
                          ? pageContent?.TABLE_TYPE_DROPTDOWN_LABEL
                          : ""
                      }
                      value={restaurantReservationData.tableType}
                      className={classes.selectField2}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setRestaurantReservationData({
                          ...restaurantReservationData,
                          tableType: event.target.value
                        });
                        slotLock({
                          variables: {
                            body: {
                              restaurantId: rid,
                              partySize: partySize,
                              dateTime:
                                moment(reservationDate).format("yyyy-MM-DD") +
                                `T` +
                                reservationTime,
                              reservationAttribute: event.target.value
                            }
                          }
                        });
                      }}
                      inputProps={{
                        "data-testid": "table-type"
                      }}
                      disabled={tableTypeOptions.length === 1}
                    >
                      {tableTypeOptions.length > 1 && (
                        <option
                          key={TABLE_TYPE_NO_PREFERENCE}
                          value={TABLE_TYPE_NO_PREFERENCE}
                        >
                          {pageContent?.SELECT_TABLE_TYPE}
                        </option>
                      )}
                      {tableTypeOptions.map(tableType => (
                        <option
                          key={`tabletype-${tableType}`}
                          value={tableType}
                        >
                          {getTableTypeDropdownLabel(tableType)}
                        </option>
                      ))}
                    </BlurSelectValidator>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <BlurTextValidator
                    fullWidth
                    variant="outlined"
                    className={classes.guestDetailsField}
                    label={pageContent?.FIRST_NAME}
                    value={restaurantReservationData.firstName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setRestaurantReservationData({
                        ...restaurantReservationData,
                        firstName: event.target.value
                      })
                    }
                    name="firstName"
                    id="firstName"
                    inputProps={{
                      "data-testid": "first-name",
                      className: classes.rhBackgroundColor
                    }}
                    validators={["required", "minStringLength:2"]}
                    errorMessages={[
                      pageContent?.FIRST_NAME_REQ,
                      pageContent?.MUST_BE_ATLEAST_TWO_CHAR
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <BlurTextValidator
                    fullWidth
                    variant="outlined"
                    className={classes.guestDetailsField}
                    label={pageContent?.LAST_NAME}
                    value={restaurantReservationData.lastName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setRestaurantReservationData({
                        ...restaurantReservationData,
                        lastName: event.target.value
                      })
                    }
                    name="lastName"
                    id="lastName"
                    inputProps={{
                      "data-testid": "last-name",
                      className: classes.rhBackgroundColor
                    }}
                    validators={["required", "minStringLength:2"]}
                    errorMessages={[
                      pageContent?.LAST_NAME_REQ,
                      pageContent?.MUST_BE_ATLEAST_TWO_CHAR
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <BlurTextValidator
                    fullWidth
                    variant="outlined"
                    className={classes.guestDetailsField}
                    label={pageContent?.EMAIL}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setRestaurantReservationData({
                        ...restaurantReservationData,
                        email: event.target.value
                      })
                    }
                    name="email"
                    id="email"
                    value={restaurantReservationData.email}
                    inputProps={{
                      "data-testid": "email",
                      className: classes.rhBackgroundColor
                    }}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      pageContent?.EMAIL_REQ,
                      pageContent?.NOT_VALID_EMAIL
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <RHPhoneInput
                    value={restaurantReservationData.phone}
                    defaultCountry={restaurant?.address.country.toLowerCase()!}
                    onChange={(phone, countryCode) => {
                      setRestaurantReservationData({
                        ...restaurantReservationData,
                        phone: phone,
                        countryCode: getCountryCodeFromISO(countryCode)
                      });
                    }}
                    errorMessages={[pageContent?.PHONE_REQ]}
                  />
                </Grid>

                <Grid item container xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <BlurSelectValidator
                      id="occasion"
                      name="occasion"
                      fullWidth
                      select
                      variant="outlined"
                      value={restaurantReservationData.occasion}
                      className={classes.selectField2}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setRestaurantReservationData({
                          ...restaurantReservationData,
                          occasion: event.target.value
                        })
                      }
                      inputProps={{
                        "data-testid": "occasion",
                        className: classes.rhBackgroundColor
                      }}
                    >
                      <option
                        key={"NO_PREFERENCE_OCCASION"}
                        value={"NO PREFERENCE"}
                      >
                        {pageContent?.SELECT_OCCASION}
                      </option>
                      <option key={"BIRTHDAY"} value={"Birthday"}>
                        {pageContent?.OCCASION_BIRTHDAY}
                      </option>
                      <option key={"ANNIVERSARY"} value={"Anniversary"}>
                        {pageContent?.OCCASION_ANNIVERSARY}
                      </option>
                      <option key={"DATE_NIGHT"} value={"Date night"}>
                        {pageContent?.OCCASION_DATE_NIGHT}
                      </option>
                      <option key={"BUSINESS_MEAL"} value={"Business meal"}>
                        {pageContent?.OCCASION_BUSINESS_MEAL}
                      </option>
                      <option key={"CELEBRATION"} value={"Celebration"}>
                        {pageContent?.OCCASION_CELEBRATION}
                      </option>
                    </BlurSelectValidator>
                  </FormControl>
                </Grid>
                <Grid item container xs={12}>
                  <BlurTextValidator
                    fullWidth
                    variant="outlined"
                    placeholder={pageContent?.SPECIAL_REQUEST}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setRestaurantReservationData({
                        ...restaurantReservationData,
                        specialRequest: event.target.value
                      })
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      "data-testid": "special-request",
                      className: classes.rhBackgroundColor
                    }}
                    multiline
                    rows="6"
                    name="special-request"
                    id="special-request"
                    value={restaurantReservationData.specialRequest}
                    className={classes.specialRequestTextArea}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.yourDetailsFormContainer}>
              <Box className={classes.reservationSummary}>
                <Grid
                  item
                  style={{ flex: isSmallTablet ? "0 0 180px" : "0 0 100%" }}
                >
                  <Typography
                    className={classNames([rhTypoClasses.rhBaseBody1])}
                  >
                    <Typography
                      className={classNames([
                        classes.reservationSummaryLineItem
                      ])}
                      style={{ paddingBottom: "16px" }}
                    >
                      {restaurant?.name}
                    </Typography>

                    <Typography
                      className={classNames([
                        classes.reservationSummaryLineItem
                      ])}
                      style={{
                        paddingBottom: "16px",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <RHDarkCalendarIcon
                        style={{
                          width: "12px",
                          height: "13px",
                          fill: "none",
                          marginRight: "12px"
                        }}
                      />
                      {restaurantInquiryData.reservationDate.format(
                        "yyyy.MM.DD"
                      )}
                    </Typography>

                    <Typography
                      className={classNames([
                        classes.reservationSummaryLineItem
                      ])}
                      style={{
                        paddingBottom: "16px",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <RHTimeIcon
                        style={{
                          width: "14px",
                          height: "14px",
                          fill: "none",
                          marginRight: "12px"
                        }}
                      />
                      {getReservationTimeLabel()}
                    </Typography>

                    <Typography
                      className={classNames([
                        classes.reservationSummaryLineItem
                      ])}
                      style={{
                        paddingBottom: "16px",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <RHPersonIcon
                        style={{
                          width: "14px",
                          height: "14px",
                          marginRight: "12px"
                        }}
                      />
                      {restaurantInquiryData.partySize}
                    </Typography>

                    {restaurant && (
                      <Typography
                        className={classNames([rhTypoClasses.rhBaseBody1])}
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <Typography>
                          <RHGalleryLocatorIcon
                            style={{
                              width: "16px",
                              height: "16px",
                              marginRight: "12px"
                            }}
                          />
                        </Typography>

                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography
                            className={classNames([
                              classes.reservationSummaryAddressLine
                            ])}
                          >
                            <div>{restaurant?.address?.streetLine1},</div>
                            {restaurant?.address?.streetLine2 ? (
                              <div>{` ${restaurant?.address?.streetLine2},`}</div>
                            ) : (
                              ""
                            )}
                            <div>
                              {restaurant?.address?.city},{" "}
                              {restaurant?.address?.state}{" "}
                              {restaurant?.address?.postalCode}
                            </div>
                          </Typography>
                          <Link
                            className={classNames(
                              classes.getDirectionsTextLink
                            )}
                            href={`https://www.google.com/maps/search/?api=1&query=${restaurant?.name},${restaurant?.address?.streetLine1},${restaurant?.address?.city},${restaurant?.address?.state},${restaurant?.address?.country}`}
                            underline="always"
                            target="_blank"
                          >
                            {pageContent?.TEXT_GET_DIRECTION}
                          </Link>
                        </Box>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{ flex: isSmallTablet ? "0 0 1px" : "0 0 100%" }}
                >
                  <RHDivider
                    orientation={isSmallTablet ? "vertical" : "horizontal"}
                    style={{
                      color: "#F5F5F5",
                      backgroundColor: "#DDDDDD"
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classNames([rhTypoClasses.rhBaseBody1])}
                  >
                    {Policies && Policies.length > 0 && (
                      <>
                        <Typography
                          className={classNames([
                            classes.restaurantNote,
                            rhTypoClasses.uppercaseText
                          ])}
                          style={{ paddingBottom: "8px" }}
                        >
                          {pageContent?.RESTAURANT_NOTE_HEADER}
                        </Typography>

                        <Typography
                          className={classNames([classes.restaurantNote])}
                        >
                          {Policies[0]?.Message}
                        </Typography>
                        <br />
                        <br />
                      </>
                    )}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            {smDown && (
              <Grid
                item
                style={{ paddingBottom: "20px" }}
                className={classes.yourDetailsFormContainer}
              >
                <RHDivider
                  style={{
                    color: "#F5F5F5"
                  }}
                />
              </Grid>
            )}
            <Grid item className={classes.yourDetailsFormFooter}>
              <Grid
                item
                container
                className={classes.yourDetailsFormContainer}
                alignItems="flex-start"
              >
                <FormControlLabel
                  id="getEmailUpdates"
                  control={
                    <Checkbox
                      checked={restaurantReservationData.emailMarketingOptIn}
                      onChange={e => {
                        setRestaurantReservationData({
                          ...restaurantReservationData,
                          emailMarketingOptIn:
                            !restaurantReservationData.emailMarketingOptIn
                        });
                      }}
                      style={{ width: 16, height: 16, fontSize: 12 }}
                      className={classes.getEmailUpdatesCheckbox}
                    />
                  }
                  label={
                    <Typography
                      className={classNames([classes.marketingOptIn])}
                    >
                      {pageContent?.CTA_GET_EMAIL_UPDATES_RH}
                    </Typography>
                  }
                />
              </Grid>
              <Grid
                item
                container
                justify="center"
                style={{ padding: 0 }}
                className={classes.confirmReservationButtonContainer}
              >
                <FormControl>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    fullWidth
                    className={classes.confirmReservationButton}
                    disabled={
                      firstName?.length === 0 ||
                      lastName?.length === 0 ||
                      email?.length === 0 ||
                      phone?.length === 0 ||
                      confirmReservationError.length > 0
                    }
                  >
                    {pageContent?.CONFIRM_RESERVATION_BUTTON_TEXT}
                  </Button>
                </FormControl>
              </Grid>
              {confirmReservationError && confirmReservationError.length > 0 && (
                <Grid
                  item
                  container
                  justify="center"
                  style={{ padding: 0 }}
                  className={classes.confirmReservationErrorContainer}
                >
                  <Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: confirmReservationError
                      }}
                    />
                  </Typography>
                </Grid>
              )}

              <Typography className={classNames([classes.termsOfUse])}>
                {pageContent?.TEXT_OPENTABLE_DISCLAIMER}
                <span>
                  <Link
                    className={classNames([classes.termsOfUse])}
                    href={`https://www.opentable.com/legal/terms-and-conditions`}
                    underline="always"
                    target="_blank"
                  >
                    {pageContent?.TEXT_TERMS_OF_USE}
                  </Link>
                </span>
                <span style={{ lineHeight: "2em" }}>
                  {pageContent?.TEXT_AND}
                </span>
                <span>
                  <Link
                    className={classNames([classes.termsOfUse])}
                    href={`https://www.opentable.com/legal/privacy-policy`}
                    underline="always"
                    target="_blank"
                  >
                    {pageContent?.TEXT_PRIVACY_POLICY}
                  </Link>
                  .
                </span>
              </Typography>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Grid>
    );
  };

  return (
    <FormDialog
      open={openState}
      onClose={handleClose}
      PaperProps={{ className: classes.paper, square: true }}
    >
      {reservationFormState !== RESERVATION_FORM_STATE.BOOKING_DONE && (
        <Grid container>
          <Grid item container justify="center" alignItems="center">
            <Typography variant="h3" className={classes.formTitle}>
              {pageContent?.MAKE_RESERVATION}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            style={{ padding: "16px 0 60px" }}
          >
            <Typography variant="h2" className={classes.restaurantName}>
              {restaurantInquiryData.restaurant?.name}
            </Typography>
          </Grid>
          <Box style={{ width: "100%" }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="opentable reservation dialog tabs"
              centered
              className={classes.tabs}
              classes={{
                root: classes.tabsRoot
              }}
              TabIndicatorProps={{ children: <span /> }}
            >
              <Tab
                value={TAB_PANEL.FIND_TABLE}
                icon={
                  activeTab === TAB_PANEL.YOUR_DETAILS ? (
                    <RHCheckmarkIcon />
                  ) : (
                    ""
                  )
                }
                label={
                  activeTab === TAB_PANEL.YOUR_DETAILS
                    ? `${pageContent?.FIND_TABLE}`
                    : `1. ${pageContent?.FIND_TABLE}`
                }
                className={classes.tabButton}
                classes={{
                  root: classes.tabRoot
                }}
              />
              <Tab
                value={TAB_PANEL.YOUR_DETAILS}
                label={`2. ${pageContent?.YOUR_DETAILS}`}
                className={classes.tabButton}
                classes={{
                  root: classes.tabRoot
                }}
              />
            </Tabs>
            <div
              role="tabpanel"
              hidden={activeTab !== TAB_PANEL.FIND_TABLE}
              id={`find-table-panel`}
              aria-labelledby={`find-table-panel`}
            >
              {activeTab === TAB_PANEL.FIND_TABLE && (
                <Box className={classes.findTableContainer}>
                  {restaurantEnquiryForm()}
                </Box>
              )}
            </div>
            <div
              role="tabpanel"
              hidden={activeTab !== TAB_PANEL.YOUR_DETAILS}
              id={`reservation-details-panel`}
              aria-labelledby={`reservation-details-panel`}
            >
              {activeTab === TAB_PANEL.YOUR_DETAILS && (
                <Box className={classes.yourDetailsPanelContainer}>
                  {reservationDetailsForm()}
                </Box>
              )}
            </div>
          </Box>
          <Box style={{ width: "100%", paddingTop: "20px" }}>
            <Grid item container justify="center" alignItems="center">
              <Typography className={classes.openTablePoweredBy}>
                {"Powered by"}
              </Typography>
            </Grid>
            <Grid item container justify="center" alignItems="center">
              <Typography className={classes.openTableIcon}>
                <OpenTableIcon style={{ width: "80px", height: "auto" }} />
              </Typography>
            </Grid>
          </Box>
        </Grid>
      )}
      {reservationFormState === RESERVATION_FORM_STATE.BOOKING_DONE && (
        <Grid container className={classes.bookingConfirmationContainer}>
          <Grid item style={{ width: smDown ? "320px" : "540px" }}>
            <Typography variant="h3" className={classes.formTitle}>
              {pageContent?.BOOKING_CONFIRMATION_GREETING_TITLE}
            </Typography>
            <Typography
              className={classes.bookingConfirmationMessage}
              style={{ paddingTop: "20px" }}
            >
              {confirmationMessage}
            </Typography>
            <Typography
              className={classes.bookingConfirmationMessage}
              style={{ paddingTop: "8px" }}
            >
              {pageContent?.BOOKING_CONFIRMATION_TEXT_CONFIRMATION}{" "}
              {confirmationNumber}
            </Typography>
            <Typography
              className={classes.bookingConfirmationMessage}
              style={{ paddingTop: "8px" }}
            >
              {pageContent?.BOOKING_CONFIRMATION_TEXT_ENQUIRY ||
                "If you have any questions ahead of your reservation, please contact"}{" "}
              <p style={{ fontWeight: 600, margin: 0 }}>
                {restaurantInquiryData.restaurant?.name}
              </p>
              {" at "}{" "}
              <span style={{ fontWeight: 600 }}>
                {restaurantInquiryData.restaurant?.phoneNumber}
              </span>{" "}
              {pageContent?.BOOKING_CONFIRMATION_TEXT_TYPE}
            </Typography>
          </Grid>
        </Grid>
      )}
    </FormDialog>
  );
};

OpenTableReservationDialog.defaultProps = {};
export default memoize(OpenTableReservationDialog);

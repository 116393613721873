import gql from "graphql-tag";

export const queryBookingPolicies = gql`
  query GetBookingPolicies(
    $rid: Float!
    $partySize: Float!
    $date: String!
    $time: String!
  ) {
    getBookingPolicies(
      rid: $rid
      date: $date
      time: $time
      partySize: $partySize
    ) {
      Policies {
        Message
      }
    }
  }
`;

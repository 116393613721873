import gql from "graphql-tag";

export const mutationMakeReservation = gql`
  mutation MakeReservation($body: MakeReservationInput!) {
    makeReservation(body: $body) {
      message
      confirmation_number
      date_time
      party_size
      notes
      manage_reservation_url
    }
  }
`;

import gql from "graphql-tag";

export const queryRestaurants = gql`
  query Restaurants {
    restaurants {
      openTableID
      name
      url
      phoneNumber
      maxGuestNumber
      hours
      generalEmailAddress
      address {
        mallName
        streetLine1
        streetLine2
        city
        county
        state
        country
        postalCode
        latitude
        longitude
      }
    }
  }
`;

import React from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { InputAdornment, Theme, useMediaQuery } from "utils/material-ui-core";
import {
  DatePicker,
  DatePickerProps,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import RHDropdownIcon from "icons/RHDropdownIcon";
import RHDarkCalendarIcon from "icons/RHDarkCalendarIcon";
import memoize from "utils/memoize";

class CustomMomentUtils extends MomentUtils {
  getWeekdays() {
    // hack to generate a custom week day label
    return moment.weekdaysShort().map(d => d.substring(0, 1));
  }
}

export type RHCustomDatePickerProps = DatePickerProps & {
  iconColor?: any;
}; // We might want to extend this @material-ui type

const RHCustomDatePicker = (props: RHCustomDatePickerProps) => {
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const {
    children,
    error,
    fullWidth,
    helperText,
    id,
    inputProps,
    label,
    onBlur,
    onChange,
    value,
    style,
    className,
    iconColor
  } = props;
  return (
    <MuiPickersUtilsProvider utils={CustomMomentUtils}>
      <DatePicker
        disableToolbar
        autoOk
        disablePast
        style={style ? style : undefined}
        className={className ? className : undefined}
        inputVariant="outlined"
        variant={smDown ? "dialog" : "inline"}
        format={"MMM DD, yyyy"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <RHDarkCalendarIcon
                style={{ width: "12px", height: "12px", fill: "none" }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <RHDropdownIcon color={iconColor ? iconColor : undefined} />
          )
        }}
        emptyLabel=""
        children={children}
        error={error}
        helperText={helperText}
        id={id}
        inputProps={inputProps}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        fullWidth={fullWidth}
      />
    </MuiPickersUtilsProvider>
  );
};

export default memoize(RHCustomDatePicker);

import moment from "moment";
import { useEffect, useState } from "react";

const useCountdown = (targetDate: string) => {
  const countDownDate = moment.utc(targetDate).valueOf();
  const [countDown, setCountDown] = useState(
    countDownDate - moment.utc().valueOf()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - moment.utc().valueOf());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = countDown => {
  // calculate time left
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  const isExpired = minutes + seconds <= 0;

  return [minutes, seconds, isExpired];
};

export { useCountdown };
